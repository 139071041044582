<template>
  <transition name="toggle">
    <div
      v-show="value"
      class="dialog-mask"
    >
      <v-flex
        class="d-flex justify-center mb-6">
        <material-card
          max-width="100%"
          color="green"
        >
          <v-flex
            xs12
            md12
          >
            <v-text-field
              v-model="deviceGroupDialog.devicegroupname"
              label="设备组名称"
              required
              @keyup.enter="createGroup"
            />
          </v-flex>
          <v-container
            fill-height
            fluid
            grid-list-xl
            style="padding-top: 0"
          >
            <v-flex
              xs12
              md6
              style="padding: 0"
            >
              <v-text-field
                v-model="leftsearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-flex>
            <v-flex
              xs12
              md1/>
            <v-flex
              xs12
              md6
              style="padding: 0"
            >
              <v-text-field
                v-model="rightsearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-flex>
          </v-container>
          <v-container
            fill-height
            fluid
            grid-list-xl
            style="padding-top: 0"
          >
            <v-layout
              justify-center
              wrap
            >
              <v-flex
                md5
              >
                <material-card
                  color="green"
                >
                  <v-data-table
                    :headers="leftheaders"
                    :items="leftitems"
                    :search="leftsearch"
                    :pagination.sync="leftpagination"
                    :rows-per-page-items="[leftpagination.rowsPerPage]"
                    v-model="leftselected"
                    :expand="true"
                    class="elevation-3"
                    select-all
                  >
                    <template
                      v-slot:items="rowsprops"
                    >
                      <tr >
                        <td>
                          <v-checkbox
                            v-model="rowsprops.selected"
                            color="#42a5f5"
                            hide-details
                          />
                        </td>
                        <td>{{ rowsprops.item.devicename }}</td>
                        <td>{{ rowsprops.item.devicemodel }}</td>
                        <td style="padding-top: 0;padding-bottom: 0">
                          <v-btn
                            slot="activator"
                            class="v-btn--small"
                            color="#42a5f5"
                            icon
                            @click.stop="addright(rowsprops.item)"
                          >
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </material-card>
              </v-flex>
              <v-flex
                xs12
                md1
                style="padding: 0"
              >
                <v-container
                  fluid
                  grid-list-xl
                >
                  <v-flex
                    xs12
                    md5
                  >
                    <v-btn
                      slot="activator"
                      icon
                      color="#42a5f5"
                      @click.stop="multiaddright(leftselected)"
                    >
                      <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs12
                    md5
                  >
                    <v-btn
                      slot="activator"
                      icon
                      color="#42a5f5"
                      @click.stop="multiaddleft(rightselected)"
                    >
                      <v-icon>mdi-chevron-double-left</v-icon>
                    </v-btn>
                  </v-flex>
                </v-container>
              </v-flex>
              <v-flex
                xs12
                md5
              >
                <material-card
                  color="green"
                >
                  <v-data-table
                    :headers="rightheaders"
                    :items="rightitems"
                    :search="rightsearch"
                    :pagination.sync="rightpagination"
                    :rows-per-page-items="[rightpagination.rowsPerPage]"
                    v-model="rightselected"
                    class="elevation-3 "
                    select-all
                  >
                    <template
                      v-slot:items="rowsprops"
                    >
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="rowsprops.selected"
                            color="#42a5f5"
                            hide-details
                          />
                        </td>
                        <td style="padding-top: 0;padding-bottom: 0">
                          <v-btn
                            slot="activator"
                            class="v-btn--small"
                            color="#42a5f5"
                            icon
                            @click.stop="addleft(rowsprops.item)"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                        </td>
                        <td>{{ rowsprops.item.devicename }}</td>
                        <td>{{ rowsprops.item.devicemodel }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </material-card>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container py-2>
            <v-flex
              xs12
              text-xs-right
              style="margin-right:20px"
            >
              <v-btn
                class="mx-1 font-weight-light"
                color="success"
                @click="createGroup"
              >
                保存
              </v-btn>
              <v-btn
                class="mx-1 font-weight-light"
                color="success"
                @click="cancel"
              >
                关闭
              </v-btn>
            </v-flex>
          </v-container>
        </material-card>
      </v-flex>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DeviceGroupAdd',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      leftsearch: '',
      rightsearch: '',
      leftselected: [],
      rightselected: [],
      deviceGroupDialog: {
        devicegroupname: '',
        devicemodel: '',
        rightids: []
      },
      leftpagination: { page: 1, rowsPerPage: 5, totalItems: 0, sortBy: '' },
      rightpagination: { page: 1, rowsPerPage: 5, totalItems: 0, sortBy: '' },
      selectedItems: ['5', '10', '20', '50', '100'],
      leftheaders: [
        { sortable: true, text: '设备名称', value: 'devicename', width: '40%' },
        { sortable: true, text: '设备型号', value: 'devicemodel', width: '40%' },
        { sortable: false, text: '', value: '', width: '8%' }
      ],
      leftitems: [],
      rightheaders: [
        { sortable: false, text: '', value: '', width: '5%' },
        { sortable: true, text: '设备名称', value: 'devicegroupname', width: '40%' },
        { sortable: true, text: '设备型号', value: 'devicemodel', width: '40%' }
      ],
      rightitems: []
    }
  },
  computed: {
  },
  watch: {
    value (newVal, oldVal) {
      this.renew(newVal)
    }
  },
  created () {
    // console.log('created ()')
  },
  methods: {
    renew (value) {
      console.log('renew (value): ', value)
      if (value) {
        this.deviceGroupDialog.devicegroupname = '新设备组'
        this.deviceGroupDialog.devicemodel = ''
        this.leftitems = []
        this.rightitems = []
        this.rightpagination.totalItems = 0
        this.leftdeviceList()
      }
    },
    leftdeviceList () {
      this.leftitems = []
      this.$http
        .get(this.$store.state.app.url + '/mediaSystem/device/noGroupDeviceList', this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            for (let i = 0; i < res.detail.list.length; i++) {
              let obj = {
                'id': res.detail.list[i].id,
                'devicename': res.detail.list[i].description,
                'uploadtime': this.$getDateString(res.detail.list[i].timestamp),
                'devicemodel': res.detail.list[i].serialCode,
                'index': i + 1
              }
              this.leftitems.push(obj)
            }
            this.leftpagination.totalItems = res.detail.list.length
            this.leftpagination.rowsPerPage = 5
            this.leftpagination.page = 1
            // console.log(res.detail)
          } else {
            this.$snackbar.error(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    addright (obj) {
      for (let i = 0; i < this.leftitems.length; i++) {
        if (this.leftitems[i].id === obj.id) {
          this.leftitems.splice(i, 1)
          let rightobj = {
            'id': obj.id,
            'devicename': obj.devicename,
            'uploadtime': this.$getDateString(obj.uploadtime),
            'devicemodel': obj.devicemodel
          }
          this.deviceGroupDialog.rightids.push(obj.id)
          this.rightitems.unshift(rightobj)
          this.rightpagination.totalItems++
          this.leftpagination.totalItems--
        }
      }
    },
    addleft (obj) {
      for (let i = 0; i < this.rightitems.length; i++) {
        if (this.rightitems[i].id === obj.id) {
          this.rightitems.splice(i, 1)
          let leftobj = {
            'id': obj.id,
            'devicename': obj.devicename,
            'uploadtime': this.$getDateString(obj.uploadtime),
            'devicemodel': obj.devicemodel
          }
          for (let j = 0; j < this.deviceGroupDialog.rightids.length; j++) {
            if (this.deviceGroupDialog.rightids[j] === obj.id) {
              this.deviceGroupDialog.rightids.splice(j, 1)
            }
          }
          this.leftitems.unshift(leftobj)
          this.rightpagination.totalItems--
          this.leftpagination.totalItems++
        }
      }
    },
    multiaddright (arr) {
      for (let i = 0; i < arr.length; i++) {
        let rightobj = {
          'id': arr[i].id,
          'devicename': arr[i].devicename,
          'uploadtime': this.$getDateString(arr[i].uploadtime),
          'devicemodel': arr[i].devicemodel
        }
        for (let j = 0; j < this.leftitems.length; j++) {
          if (this.leftitems[j].id === arr[i].id) {
            this.leftitems.splice(j, 1)
          }
        }
        this.rightitems.unshift(rightobj)
        this.rightpagination.totalItems++
        this.leftpagination.totalItems--
      }
    },
    multiaddleft (arr) {
      for (let i = 0; i < arr.length; i++) {
        let leftobj = {
          'id': arr[i].id,
          'devicename': arr[i].devicename,
          'uploadtime': this.$getDateString(arr[i].uploadtime),
          'devicemodel': arr[i].devicemodel
        }
        for (let j = 0; j < this.rightitems.length; j++) {
          if (this.rightitems[j].id === arr[i].id) {
            this.rightitems.splice(j, 1)
          }
        }
        this.leftitems.unshift(leftobj)
        this.rightpagination.totalItems--
        this.leftpagination.totalItems++
      }
    },
    submit (detail) {
      this.$emit('viewClosed', detail)
    },
    cancel () {
      this.$emit('viewClosed')
    },
    createGroup () {
      this.leftdeviceList()
      if (this.deviceGroupDialog.devicegroupname === '') {
        this.$snackbar.info('设备组名称不能为空')
        return
      }
      if (this.rightitems.length === 0) {
        this.$snackbar.info('需要添加的设备不能为空')
        return
      }
      if (this.deviceGroupDialog.devicegroupname.length > 50) {
        this.$snackbar.info('请输入少于50个字符')
        return
      }
      let formdata = new FormData()
      formdata.append('groupName', this.deviceGroupDialog.devicegroupname)
      // console.log('this.rightitems: ', this.rightitems)
      // console.log('this.deviceGroupDialog.rightids: ', this.deviceGroupDialog.rightids)
      formdata.append('deviceIds[]', this.deviceGroupDialog.rightids)
      this.$http
        .post(this.$store.state.app.url + '/mediaSystem/group/createGroup', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            this.$snackbar.success(res.message)
            // console.log(result.data.detail)
            this.submit(result.data.detail)
          } else {
            this.$snackbar.error(res.message)
          }
        }, (error) => {
          this.$httpCatch(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .dialog-mask {
    background-color: #eeeeee;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    display: table;
    animation-duration: 0s;
  }
</style>
